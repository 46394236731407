import { Message } from "element-ui"; //项目已经全局引入element的话可以不单独引入
import router from "@/router";
import { startLoading, endLoading } from "@/utils/function";
import API from "@/api/teacher/questionbank/questionPreview";
import { nprogressStart, nprogressClose } from "@/plugins/nprogress.js";
export default {
    name: "questionPreview",
    components: {},
    data() {
        return {
            questionData:[],
        }
    },
    created()
    {
        let self=this;
        let data = {
            bank_id:self.$route.query.bank_id
        }
        nprogressStart();
        self.$Loading.show();
        API.getPreviewQuestionBank(data)
                .then((result)=>{
                    self.$Loading.hide();
                    if(result.code > 0)
                    {
                        self.questionData=result.questionList
                    }
                    // 关闭loading
                    // endLoading(startLoading());
                    nprogressClose();
                })
                .catch(()=>{
                    // 关闭loading
                    // endLoading(startLoading());
                    nprogressClose();
                })
    },
    methods:{
        /**
         * @name: 滑动窗口到指定dom位置
         * @author: camellia
         * @date: 2022-04-01
         */
        scroll(index) 
        {
        document
            .getElementsByName("item")
            [index].scrollIntoView({ behavior: "smooth" });
        },
        handleBack()
        {
            var self=this;
            self.$router.back();
        }
    }
}