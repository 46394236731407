<template>
    <div class="questionPreview">
        <!-- <div class="buttonBack"><el-button type="primary" @click="handleBack">返回</el-button></div> -->
        <div class="previewBox">

        
            <div class="questionDiv" :id="index" v-for="(item,index) in questionData" :key="item.exam_id" name="item">
            <div class="questionHeader"> 
                <span class="questionIndex">{{ index + 1}}.</span>
                <span class="questionScore" v-show="item.questionType=='radio'">(单选,{{item.questionScore}}分)</span>
                <span class="questionScore" v-show="item.questionType=='judge'">(判断,{{item.questionScore}}分)</span>
                <span class="questionScore" v-show="item.questionType=='checkbox'">(多选,{{item.questionScore}}分)</span>
                <div class="questionInfo" v-html="item.defaultMsg"></div>
                
            </div>
           <div class="questionCheckBox" v-if="item.questionType=='checkbox'">
                <el-checkbox-group v-model="item.checkbox_right_key">
                    <div class="checkboxItem" v-for="(item3,index3) in item.optionList" :key="index3" >
                        <el-checkbox :label="item3.index"></el-checkbox>
                    </div>
                </el-checkbox-group>
           </div>
            <div  class="questionRadio" v-for="(item1) in item.optionList" :key="item1.index"  v-else-if="item.questionType=='radio'">
                 <el-radio class="questionSelect"  
                            v-model="item.radio_right_key" 
                            :label="item1.index">{{item1.index}}</el-radio>
                 <!-- <el-input v-model="item1.content"></el-input> -->
            </div>
            <div  class="questionRadio" v-for="(item2) in item.optionList" :key="item2.index"  v-else>
                 <el-radio class="questionSelect"  
                            v-model="item.judge_right_key" 
                            :label="item2.index">{{item2.index}}</el-radio>
                 <!-- <el-input v-model="item1.content"></el-input> -->
            </div>
             <!-- <el-divider></el-divider> -->
            </div>
        </div>
        <div class="questionIndex2">
                <div class="questionTitle">题目</div>
                <!-- <div class="scrollquestionIndex">
                    <div class="indexButton"  v-for="(item,index) in questionData" :key="index"><a :href="'#'+index">{{index+1}}</a></div>   
                </div> -->
                  <el-button
                    class="scrollindexButton"
                        v-for="(item, index) in questionData"
                        :key="item.id"
                        @click="scroll(index)"
                        >{{ index + 1 }}
                        </el-button>
            </div>
    </div>
</template>

<script>
    import questionPreview from "@/assets/js/teacher/questionbank/questionPreview.js";
    export default {
        ...questionPreview
    };
</script>
<style>
.questionPreview .el-radio__label{
                font: normal normal normal  0.8333vw Source Han Sans CN !important;
            }
.questionPreview .el-radio__input.is-checked .el-radio__inner{
    border-color: #0d5570;
    background: #0d5570;
}
.questionPreview .el-radio__inner{
    width: 0.7292vw;
    height:  0.7292vw;
}
.questionPreview .el-radio__input.is-checked+.el-radio__label{
    color: #0D5570;
}
.questionPreview .el-checkbox__input.is-checked+.el-checkbox__label{
    color: #0D5570;
    font: normal normal normal  0.8333vw Source Han Sans CN !important;
}
.questionPreview .el-checkbox__input.is-checked .el-checkbox__inner,.questionPreview .el-checkbox__input.is-indeterminate .el-checkbox__inner {
    background-color: #0D5570;

    border-color: #0D5570;
}
.questionPreview .el-checkbox__label{
    font: normal normal normal  0.8333vw Source Han Sans CN !important;
}
</style>
<style  lang="scss" scoped>
    @import "@/assets/css/teacher/questionbank/questionPreview.scss";
</style>